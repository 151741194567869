import React from 'react'
import "./OnLoad.css"
import $ from "jquery"
function OnLoad() {
    $(window).on("load", () => {
        $(".loader-wrapper").fadeOut("slow");
    });
    return (
      <div>
        <div class="loader-wrapper">
          <span class="loader">
            <span class="loader-inner"></span>
          </span>
        </div>
      </div>
    );
}

export default OnLoad
