import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Blog from "./components/Blog/Blog";
import Pricing from "./components/Pricing/Pricing";
import CheapPrice from "./components/Pricing/CheapPrice";
import WordpressPrice from "./components/Pricing/WordpressPrice";
import BusinessPrice from "./components/Pricing/BusinessPrice";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import AOS from "aos";
import "aos/dist/aos.css";
// import Login from './components/Auth/Login';
import Footer from "./components/Footer";
import TopContact from "./components/TopContact";
import VpsPrice from "./components/Pricing/VpsPrice";
import OnLoad from "./components/OnLoad"
import $ from "jquery"
function App() {
  const gotoup = () => {
    window.scrollTo(0, 0);
  };
  // // eslint-disable-next-line no-use-before-define
  // var Tawk_API = Tawk_API || {},
  //   // eslint-disable-next-line no-unused-vars
  //   Tawk_LoadStart = new Date();
  // Tawk_API.embedded = "tawk_59afb581c28eca75e461e67e";
  // (function () {
  //   var s1 = document.createElement("script"),
  //     s0 = document.getElementsByTagName("script")[0];
  //   s1.async = true;
  //   s1.src = "https://embed.tawk.to/59afb581c28eca75e461e67e/1dmojelio";
  //   s1.charset = "UTF-8";
  //   s1.setAttribute("crossorigin", "*");
  //   s0.parentNode.insertBefore(s1, s0);
  // })();
  return (
    <>
      {/* tawk to */}
      {/* <div id="tawk_59afb581c28eca75e461e67e"></div> */}
      {/* end tawk to */}
	  <OnLoad/>
      <div className="go-to-top" onClick={gotoup}>
        <i class="fas fa-chevron-up"></i>
      </div>
      <Router>
        <TopContact />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/blog" exact component={Blog} />
          {/* <Route path="/pricing" component={Pricing}> */}
          <Route path="/hosting" component={Pricing} />
          <Route path="/cheap-hosting" exact component={CheapPrice} />
          <Route path="/wordpress-hosting" exact component={WordpressPrice} />
          <Route
            path="/business-hosting"
            exact
            render={() => <BusinessPrice sticky={true} />}
          />
          <Route path="/vps-hosting" exact component={VpsPrice} />
          {/* </Route> */}
          <Route path="/contact-us" exact component={Contact} />
          <Route path="/about-us" exact component={About} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}
AOS.init();
export default App;
