import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import PriceCard from "./PriceCard";
import "./Price.css";
import "./BusinessPrice.css";
import StickyPlan from "./StickyPlan";
import "./CheapPrice.css";
function SharedPrice() {
  useEffect(() => {
    var goto = document.getElementById("shared").offsetTop;
    window.scrollTo({ top: goto -100, behavior: "smooth" });
  }, []);
  return (
    <div id="shared">
      <StickyPlan
        plan1={{
          title: "economy",
          price: "0.95",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=56",
        }}
        plan2={{
          title: "micro",
          price: "1.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=3",
        }}
        plan3={{
          title: "mini",
          price: "3.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=4",
        }}
        plan4={{
          title: "prime",
          price: "6.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=1",
        }}
      />
      <div className="shared">
        <div className="shared-header">
          <h2>Cheap Shared SSD hosting</h2>
          <p>
            Host your website with us and sit relax, we are helping on each
            topic related to your website.
          </p>
        </div>

        <div className="row m-0 ">
          {/* <div className="col-lg-3 d-lg-block  m-0 p-0"></div> */}
          <div className="col-lg-12 col-12 p-0 m-0">
            <div className="row m-0">
              <div className="col-md-3 col-12 mx-0 p-1">
                <PriceCard
                  cardHeader="economy"
                  cardSubheader="Best for New Website"
                  cardPrice="0.95"
                  period="Monthly"
                  priceOffer={[
                    "1 Website",
                    "500 MB SSD space",
                    "10 email",
                    "10 GB Bandwidth",
                    "1 Databases",
                    "1 Subdomain",
                    "1 Addon Domain",
                    "1 Parked Domain",
                    "Free SSL ($11 value)",
                    "Free Migration",
                    "Self Managed WordPress",
                    "WordPress Acceleration",
                    "Powerful Control Panel",
                    "Multiple PHP Versions",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=56"
                />
              </div>
              <div className="col-md-3 col-12 mx-0 p-1">
                <PriceCard
                  cardHeader="micro"
                  cardSubheader="Best for Mid Website"
                  cardPrice="1.99"
                  period="Monthly"
                  priceOffer={[
                    "3 Website",
                    "1 GB SSD space",
                    "25 email",
                    "20 GB Bandwidth",
                    "3 Databases",
                    "3 Subdomain",
                    "3 Addon Domain",
                    "3 Parked Domain",
                    "Free SSL ($11 value)",
                    "Free Migration",
                    "Self Managed WordPress",
                    "WordPress Acceleration",
                    "Powerful Control Panel",
                    "Multiple PHP Versions",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=3"
                />
              </div>
              <div className="col-md-3 m-0 p-1">
                <PriceCard
                  cardHeader="mini"
                  cardSubheader="Best for Growing Website"
                  cardPrice="3.99"
                  period="Monthly"
                  priceOffer={[
                    "5 Website",
                    "5 GB SSD space",
                    "50 email",
                    "unlimited Bandwidth",
                    "5 Databases",
                    "5 Subdomain",
                    "5 Addon Domain",
                    "5 Parked Domain",
                    "Free SSL ($11 value)",
                    "Free Migration",
                    "Managed WordPress",
                    "WordPress Acceleration",
                    "Powerful Control Panel",
                    "Multiple PHP Versions",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=4"
                />
              </div>
              <div className="col-md-3 m-0 p-1">
                <PriceCard
                  cardHeader="prime"
                  cardSubheader="Best for Large Website"
                  cardPrice="6.99"
                  period="Monthly"
                  priceOffer={[
                    "unlimited Website",
                    "unlimited SSD space",
                    "unlimited email",
                    "unlimited Bandwidth",
                    "unlimited Databases",
                    "unlimited Subdomain",
                    "unlimited Addon Domain",
                    "unlimited Parked Domain",
                    "Free SSL ($11 value)",
                    "Free Migration",
                    "Managed WordPress",
                    "WordPress Acceleration",
                    "Powerful Control Panel",
                    "Multiple PHP Versions",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=1"
                />
              </div>
            </div>
          </div>
          {/* Hosting Features Table */}
          {/* <div className="col-12 m-0">
            <div className="table-responsive-lg">
              <table className="table table-bordered plans-table">
                <tbody>
                  <tr className="title-row">
                    <td colSpan="4">
                      <div className="h4">Hosting Features</div>
                    </td>
                  </tr>
                  <tr className="title-row">
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          {/* Hosting Features Table end */}
        </div>
      </div>
    </div>
  );
}

export default SharedPrice;
