import React from 'react'
import { Link } from "react-router-dom";
import "./Footer.css";
import visa from './images/payment/visa.png'
import rupay from "./images/payment/rupay.png";
import mastercard from "./images/payment/mastercard.png";
import discover from "./images/payment/discover.png";
import paypal from "./images/payment/paypal.png";
function Footer() {
    return (
      <section className="footer-container">
        <div className="footer-links">
          <div className="footer-link-wrapper">
            <div className="footer-link-items">
              <h2>company</h2>
              <Link to="/about-us">about us</Link>
              <Link to="/contact-us">contact us</Link>
              <a href="https://billing.isquarehost.com/login">client area</a>
              <Link to="/">our clients</Link>
              <Link to="/">clients reviews</Link>
              <Link to="/">site map</Link>
            </div>
            <div className="footer-link-items">
              <h2>Services</h2>
              <Link to="/cheap-hosting">Cheap Shared Hosting</Link>
              <Link to="/business-hosting">Bussiness Hosting</Link>
              <Link to="/wordpress-hosting">WordPress Hosting</Link>
              <Link to="/vps-hosting">Cloud VPS Hosting</Link>
              <a href="https://billing.isquarehost.com/cart.php?a=add&domain=register">
                register new domain
              </a>
              <a href="https://billing.isquarehost.com/cart.php?a=add&domain=transfer">
                transfer domain to us
              </a>
            </div>
            <div className="footer-link-items">
              <h2>resources</h2>
              <a href="https://www.isquarehost.com/blog">isquarehost blog</a>
            </div>
            <div className="footer-link-items">
              <h2>legal</h2>
              <Link to="/">terms of service</Link>
              <Link to="/">service level agreement</Link>
              <Link to="/">privacy policy</Link>
              <Link to="/">billing policy</Link>
            </div>
          </div>
        </div>
        <div className="social-media">
          <div className="social-media-wrap">
            <div className="footer-logo">
              <Link to="/" className="social-logo">
                iSquareHost.com
              </Link>
              <small className="website-rights">
                iSquareHost.com &#169; 2021{" "}
              </small>
            </div>
            <div className="footer-payment">
              <h3>Pay with Confidence & Security</h3>
              <div className="payment-icons">
                <Link to="/">
                  <img src={visa} alt="visa" />
                </Link>
                <Link to="/">
                  <img src={rupay} alt="rupay" />
                </Link>
                <Link to="/">
                  <img src={mastercard} alt="mastercard" />
                </Link>
                <Link to="/">
                  <img src={discover} alt="discover" />
                </Link>
                <Link to="/">
                  <img src={paypal} alt="paypal" />
                </Link>
              </div>
            </div>
            <div className="social-icons">
              <Link
                className="social-icon facebook"
                to="/"
                blank="_blank"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link
                className="social-icon instagram"
                to="/"
                blank="_blank"
                aria-label="Facebook"
              >
                <i className="fab fa-instagram"></i>
              </Link>
              <Link
                className="social-icon linkedin"
                to="/"
                blank="_blank"
                aria-label="LinkedIn"
              >
                <i className="fab fa-linkedin"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Footer
