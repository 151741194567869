import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import {OverlayTrigger, Popover} from 'react-bootstrap'
import './Navbar.css'
import "bootstrap/dist/css/bootstrap.css";
import ISL from "./images/ISL";
const domain_popover = (
  <Popover id="domain-popover">
    <Popover.Content>
      <ul className="domain-list">
        <li className="domain-link">
          <a href="https://billing.isquarehost.com/cart.php?a=add&domain=register">
            Register New Domain
          </a>
        </li>
        <li className="domain-link">
          <a href="https://billing.isquarehost.com/cart.php?a=add&domain=transfer">
            Trasfer Domain To Us
          </a>
        </li>
      </ul>
    </Popover.Content>
  </Popover>
);

function Navbar() {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)
    const [colorChange, setColorChange] = useState(false)
  const changeNavbarColor = () => {
      if (window.scrollY >= 100) {
        setColorChange(true);
        
    }
      else {
        setColorChange(false);
    }
  }
  window.addEventListener("scroll", changeNavbarColor);

    return (
      <>
        <section id="header">
          <div
            className={
              colorChange ? "header colorChange headerChange" : "header"
            }
          >
            <div className="nav-bar">
              <NavLink exact to="/" className="brand">
                <ISL />
              </NavLink>
              <div className="nav-list">
                <div
                  className={
                    colorChange ? "hamburger colorChange" : "hamburger"
                  }
                  onClick={handleClick}
                >
                  <i className={`fa ${click ? "fa-times" : "fa-bars"} ${colorChange ? "colorChange":""} `} />
                </div>
                <ul
                  className={`mynav-menu ${click ? "active" : ""} ${
                    colorChange ? "colorChange" : ""
                  }`}
                >
                  <li className="mynav-item">
                    <NavLink
                      exact
                      to="/cheap-hosting"
                      className="mynav-links"
                      id="mynav-links"
                      activeClassName="active"
                      onClick={closeMobileMenu}
                      data-after="Pricing"
                    >
                      shared
                    </NavLink>
                  </li>
                  <li className="mynav-item">
                    <NavLink
                      exact
                      to="/business-hosting"
                      className="mynav-links"
                      id="mynav-links"
                      activeClassName="active"
                      onClick={closeMobileMenu}
                      data-after="Pricing"
                    >
                      business
                    </NavLink>
                  </li>
                  <li className="mynav-item">
                    <NavLink
                      exact
                      to="/wordpress-hosting"
                      className="mynav-links"
                      id="mynav-links"
                      activeClassName="active"
                      onClick={closeMobileMenu}
                      data-after="Pricing"
                    >
                      wordpress
                    </NavLink>
                  </li>
                  <li className="mynav-item">
                    <NavLink
                      exact
                      to="/vps-hosting"
                      className="mynav-links"
                      id="mynav-links"
                      activeClassName="active"
                      onClick={closeMobileMenu}
                      data-after="Pricing"
                    >
                      cloud
                    </NavLink>
                  </li>
                  <li className="mynav-item">
                    <NavLink
                      exact
                      to="/hosting"
                      className="mynav-links"
                      id="mynav-links"
                      activeClassName="active"
                      onClick={closeMobileMenu}
                      data-after="Pricing"
                    >
                      Pricing & Plans
                    </NavLink>
                  </li>
                  <li className="mynav-item">
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={domain_popover}
                      rootClose
                    >
                      <div
                        className="mynav-links"
                        id="mynav-links"
                        data-after="domain"
                      >
                        domain
                      </div>
                    </OverlayTrigger>
                  </li>
                  <li className="mynav-item login-li">
                    <a
                      href="https://billing.isquarehost.com/login"
                      className="mynav-links login-btn"
                      id="mynav-links"
                    >
                      Login
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default Navbar
