import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route,withRouter } from "react-router-dom";
import PricingNav from './PricingNav';
import BusinessPrice from "./BusinessPrice";
import VpsPrice from "./VpsPrice";
import WordpressPrice from "./WordpressPrice";
import CheapPrice from "./CheapPrice";

export class Pricing extends Component {
  componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
          <Router>
            <PricingNav />
            <Switch>
              <Route
                path="/hosting"
                exact
                render={() => <BusinessPrice sticky={true} />}
              />
              <Route
                path="/hosting/vps-hosting"
                component={withRouter(VpsPrice)}
              />
              <Route
                path="/hosting/wordpress-hosting"
                component={withRouter(WordpressPrice)}
              />
              <Route
                path="/hosting/cheap-hosting"
                component={withRouter(CheapPrice)}
              />
            </Switch>
          </Router>
        );
    }
}

export default withRouter(Pricing);
